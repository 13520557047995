<template>
    <div>
        <Modal v-model="cronModalProps.show" title="配置Cron表达式" scrollable width="650">
            <Tabs type="card" v-model="cronTabPane" @on-click="handleChangeTab">
                <TabPane label="常用" name="0">
                    <Scroll height="230">
                        <Row :gutter="8" class="ivu-mr-0">
                            <Col span="6" v-for="(item, index) in cronModalProps.preCronMap" :key="index">
                                <Card style="margin-bottom:8px;cursor: pointer;" @click.native="cronModalProps.cronText = item;selectBack(item)">
                                    <p>{{index}}</p>
                                    <p>{{item}}</p>
                                </Card>
                            </Col>
                        </Row>
                    </Scroll>
                </TabPane>
                <TabPane label="秒" name="1">
                    <Row class="ivu-m flex_column_start">
                        <Col span="24" >
                            <RadioGroup v-model="cronForm.second.value" size="small">
                                <Radio label="secAll">所有秒</Radio>
                                <Radio label="secPartition">每隔多少秒</Radio>
                                <Radio label="secBetween">秒区段</Radio>
                                <Radio label="secGiven">特定秒</Radio>
                            </RadioGroup>
                        </Col>
                        <Col span="24" v-if="cronForm.second.value === 'secPartition'" >
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>从第 </span>
                                <div>
                                    <Select v-model="cronForm.second.secPartition.secPartitionFrom"  size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span> 秒开始，每隔 </span>
                                <div>
                                    <Select v-model="cronForm.second.secPartition.secPartitionTo"  size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span> 秒执行一次</span>
                            </div>
                        </Col>
                        <Col span="24" v-if="cronForm.second.value === 'secBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>在第 </span>
                                <div>
                                    <Select v-model="cronForm.second.secBetween.secBetweenFrom"  size="small" transfer>
                                    <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                </Select>
                                </div>
                                <span class="marLR"> 秒到 </span>
                                <div>
                                    <Select v-model="cronForm.second.secBetween.secBetweenTo" size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span class="marLR"> 秒区间执行</span>
                            </div>
                        </Col>
                        <Col span="24" v-if="cronForm.second.value === 'secGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>选择秒 </span>
                                <div>
                                    <Select v-model="cronForm.second.secGivenTo" :max-tag-count="5" size="small" multiple transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane label="分钟" name="2">
                    <Row class="ivu-m">
                        <Col span="24">
                            <RadioGroup v-model="cronForm.minute.value" size="small">
                                <Radio label="minAll">所有分钟</Radio>
                                <Radio label="minPartition">每隔多少分钟</Radio>
                                <Radio label="minBetween">分钟区段</Radio>
                                <Radio label="minGiven">特定分钟</Radio>
                            </RadioGroup>
                        </Col>
                        <Col span="24" v-if="cronForm.minute.value === 'minPartition'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>从第 </span>
                                <div>
                                    <Select v-model="cronForm.minute.minPartition.minPartitionFrom"  size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span class="marLR"> 分钟开始，每隔 </span>
                                <div>
                                    <Select v-model="cronForm.minute.minPartition.minPartitionTo" size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span class="marLR"> 分钟执行一次</span>
                            </div>
                        </Col>
                        <Col span="24" v-if="cronForm.minute.value === 'minBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>在第 </span>
                                <div>
                                    <Select v-model="cronForm.minute.minBetween.minBetweenFrom" size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span class="marLR"> 分钟到 </span>
                                <div>
                                    <Select v-model="cronForm.minute.minBetween.minBetweenTo" size="small" transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                                <span class="marLR"> 分钟区间执行</span>
                            </div>
                        </Col>
                        <Col span="24" v-if="cronForm.minute.value === 'minGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <div class="flex_row_start">
                                <span>选择分钟 </span>
                                <div>
                                    <Select v-model="cronForm.minute.minGivenTo" :max-tag-count="5" size="small" multiple transfer>
                                        <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                                    </Select>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane label="小时" name="3">
                    <Row class="ivu-m">
                        <Col span="24">
                            <RadioGroup v-model="cronForm.hour.value" size="small">
                                <Radio label="hourAll">所有小时</Radio>
                                <Radio label="hourPartition">每隔多少小时</Radio>
                                <Radio label="hourBetween">小时区段</Radio>
                                <Radio label="hourGiven">特定小时</Radio>
                            </RadioGroup>
                        </Col>
                        <Col v-if="cronForm.hour.value === 'hourPartition'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>从第 </span>
                            <Select v-model="cronForm.hour.hourPartition.hourPartitionFrom"  size="small" transfer>
                                <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 小时开始，每隔 </span>
                            <Select v-model="cronForm.hour.hourPartition.hourPartitionTo" size="small" transfer>
                                <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 小时执行一次</span>
                        </Col>
                        <Col v-if="cronForm.hour.value === 'hourBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>在第 </span>
                            <Select v-model="cronForm.hour.hourBetween.hourBetweenFrom" size="small" transfer>
                                <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 小时到 </span>
                            <Select v-model="cronForm.hour.hourBetween.hourBetweenTo" size="small" transfer>
                                <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 小时区间执行</span>
                        </Col>
                        <Col v-if="cronForm.hour.value === 'hourGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>选择小时 </span>
                            <Select v-model="cronForm.hour.hourGivenTo" :max-tag-count="5" size="small" multiple transfer>
                                <Option v-for="item in sixTenMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane label="日期" name="4">
                    <Row class="ivu-m">
                        <Col>
                            <RadioGroup v-model="cronForm.day.value" size="small">
                                <Radio label="dayAll">所有日</Radio>
                                <Radio label="dayPartition">每隔多少日</Radio>
                                <Radio label="dayBetween">日区段</Radio>
                                <Radio label="dayGiven">特定日</Radio>
                                <Radio label="dayLast">每月最后一天</Radio>
                            </RadioGroup>
                        </Col>
                        <Col v-if="cronForm.day.value === 'dayPartition'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>从第 </span>
                            <Select v-model="cronForm.day.dayPartition.dayPartitionFrom"  size="small" transfer>
                                <Option v-for="item in dayMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 日开始，每隔 </span>
                            <Select v-model="cronForm.day.dayPartition.dayPartitionTo" size="small" transfer>
                                <Option v-for="item in dayMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 日执行一次</span>
                        </Col>
                        <Col v-if="cronForm.day.value === 'dayBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>在第 </span>
                            <Select v-model="cronForm.day.dayBetween.dayBetweenFrom" size="small" transfer>
                                <Option v-for="item in dayMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 日到 </span>
                            <Select v-model="cronForm.day.dayBetween.dayBetweenFrom"  size="small" transfer>
                                <Option v-for="item in dayMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 日区间执行</span>
                        </Col>
                        <Col v-if="cronForm.day.value === 'dayGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>选择日 </span>
                            <Select v-model="cronForm.day.dayGivenTo" v-width="350" :max-tag-count="5" size="small"
                                    multiple transfer>
                                <Option v-for="item in dayMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane label="月份" name="5">
                    <Row class="ivu-m">
                        <Col>
                            <RadioGroup v-model="cronForm.month.value" size="small">
                                <Radio label="monthAll">所有月份</Radio>
                                <Radio label="monthPartition">每隔多少月</Radio>
                                <Radio label="monthBetween">月份区段</Radio>
                                <Radio label="monthGiven">特定月份</Radio>
                            </RadioGroup>
                        </Col>
                        <Col v-if="cronForm.month.value === 'monthPartition'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>从第 </span>
                            <Select v-model="cronForm.month.monthPartition.monthPartitionFrom"  size="small" transfer>
                                <Option v-for="item in monthMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 月份开始，每隔 </span>
                            <Select v-model="cronForm.month.monthPartition.monthPartitionTo"  size="small" transfer>
                                <Option v-for="item in monthMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 月份执行一次</span>
                        </Col>
                        <Col v-if="cronForm.month.value === 'monthBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>在第 </span>
                            <Select v-model="cronForm.month.monthBetween.monthBetweenFrom" size="small" transfer>
                                <Option v-for="item in monthMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 月份到 </span>
                            <Select v-model="cronForm.month.monthBetween.monthBetweenTo"  size="small" transfer>
                                <Option v-for="item in monthMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 月份区间执行</span>
                        </Col>
                        <Col v-if="cronForm.month.value === 'monthGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>选择月份 </span>
                            <Select v-model="cronForm.month.monthGivenTo" v-width="350" :max-tag-count="5" size="small" multiple transfer>
                                <Option v-for="item in monthMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane label="星期" name="6">
                    <Row class="ivu-m">
                        <Col>
                            <RadioGroup v-model="cronForm.week.value" size="small">
                                <Radio label="weekAll">所有星期</Radio>
                                <Radio label="weekPartition">每隔多少星期天数</Radio>
                                <Radio label="weekBetween">星期区段</Radio>
                                <Radio label="weekGiven">特定星期</Radio>
                                <Radio label="weekLast">每月最后一个星期</Radio>
                            </RadioGroup>
                        </Col>
                        <Col v-if="cronForm.week.value === 'weekPartition'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>从星期 </span>
                            <Select v-model="cronForm.week.weekPartition.weekPartitionFrom"  size="small" transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 开始，每隔星期 </span>
                            <Select v-model="cronForm.week.weekPartition.weekPartitionTo"  size="small" transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 执行一次</span>
                        </Col>
                        <Col v-if="cronForm.week.value === 'weekBetween'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>在星期 </span>
                            <Select v-model="cronForm.week.weekBetween.weekBetweenFrom"  size="small" transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 到星期 </span>
                            <Select v-model="cronForm.week.weekBetween.weekBetweenTo"  size="small" transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                            <span class="marLR"> 区间执行</span>
                        </Col>
                        <Col v-if="cronForm.week.value === 'weekGiven'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>选择星期 </span>
                            <Select v-model="cronForm.week.weekGivenTo" :max-tag-count="5" size="small" multiple transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                        </Col>
                        <Col v-if="cronForm.week.value === 'weekLast'">
                            <Divider size="small" orientation="left">配置</Divider>
                            <span>每月最后一个星期的星期 </span>
                            <Select v-model="cronForm.week.weekLastTo" size="small" transfer>
                                <Option v-for="item in weekMap" :value="item" :key="item">{{ item }}</Option>
                            </Select>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            <Row class="ivu-bt ivu-pt-16 margin_10" type="flex" justify="space-between" align="middle">
                <span>Cron表达式</span>
                <div>
                    <Input v-model="cronModalProps.cronText" @on-change="handleChangeCron" readonly/>
                </div>
                <Button @click="createCron" :disabled="createCronBtn">生成Cron表达式</Button>
                <Button :loading="analyLoding" @click="analyCron">解析表达式</Button>
            </Row>
            <div slot="footer">
                <Button type="text" @click="handleCancel">取消</Button>
                <Button type="primary" @click="handleOk">确定</Button>
            </div>
        </Modal>
        <Modal v-model="execTimeModal.show" title='要执行的日期' width="240" footer-hide>
            <Scroll>
                <CellGroup>
                    <Cell v-for="(item, index) in execTimeModal.times" class="ivu-text-center" :key="index">
                        <Time :time="item" type="datetime"/>
                    </Cell>
                </CellGroup>
            </Scroll>
        </Modal>
    </div>
</template>
<script>
    import { cronDate } from '/src/api/system/job';
    export default {
        name: 'cron-setting',
        props: {
            cronModalProps: {
                type: Object,
                default: () => {}
            }
        },
        data () {
            return {
                cronText: '',
                cronTabPane: '0',
                createCronBtn: true,
                execTimeModal: {
                    show: false,
                    times: []
                },
                cronForm: {
                    // 秒
                    second: {
                        value: 'secAll',
                        secPartition: {
                            secPartitionFrom: '0',
                            secPartitionTo: '0'
                        },
                        secBetween: {
                            secBetweenFrom: '0',
                            secBetweenTo: '0'
                        },
                        secGivenTo: '0'
                    },
                    // 分钟
                    minute: {
                        value: 'minAll',
                        minPartition: {
                            minPartitionFrom: '0',
                            minPartitionTo: '0'
                        },
                        minBetween: {
                            minBetweenFrom: '0',
                            minBetweenTo: '0'
                        },
                        minGivenTo: '0'
                    },
                    // 小时
                    hour: {
                        value: 'hourAll',
                        hourPartition: {
                            hourPartitionFrom: '0',
                            hourPartitionTo: '0'
                        },
                        hourBetween: {
                            hourBetweenFrom: '0',
                            hourBetweenTo: '0'
                        },
                        hourGivenTo: '0'
                    },
                    // 天
                    day: {
                        value: 'dayAll',
                        dayPartition: {
                            dayPartitionFrom: '1',
                            dayPartitionTo: '1'
                        },
                        dayBetween: {
                            dayBetweenFrom: '1',
                            dayBetweenTo: '1'
                        },
                        dayGivenTo: '1'
                    },
                    // 月
                    month: {
                        value: 'monthAll',
                        monthPartition: {
                            monthPartitionFrom: '1',
                            monthPartitionTo: '1'
                        },
                        monthBetween: {
                            monthBetweenFrom: '1',
                            monthBetweenTo: '1'
                        },
                        monthGivenTo: '1'
                    },
                    // 星期
                    week: {
                        value: 'weekAll',
                        weekPartition: {
                            weekPartitionFrom: 'SUN',
                            weekPartitionTo: 'SUN'
                        },
                        weekBetween: {
                            weekBetweenFrom: 'SUN',
                            weekBetweenTo: 'SUN'
                        },
                        weekGivenTo: 'SUN',
                        weekLastTo: 'SUN'
                    }
                },
                sixTenMap: [],
                monthMap: [],
                dayMap: [],
                weekMap: [],
                analyLoding: false
            }
        },
        created () {
            let sixTenMap = [];
            for (let i = 0; i <= 59; i++) {
                sixTenMap.push(String(i));
            }
            this.sixTenMap = sixTenMap;

            let monthMap = [];
            for (let i = 1; i <= 12; i++) {
                monthMap.push(String(i));
            }
            this.monthMap = monthMap;

            let dayMap = [];
            for (let i = 1; i <= 31; i++) {
                dayMap.push(String(i));
            }
            this.dayMap = dayMap;

            this.weekMap = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        },
        methods: {
            createCron () {
                // cronForm.second
                let secondType = this.cronForm.second.value;
                let secondExpression = '';
                let error = false;
                switch (secondType) {
                    case 'secAll':{
                        secondExpression = '*';
                        break;
                    }
                    case 'secPartition':{
                        secondExpression = this.cronForm.second.secPartition.secPartitionFrom + '/' + this.cronForm.second.secPartition.secPartitionTo;
                        break;
                    }
                    case 'secBetween':{
                        if (this.cronForm.second.secBetween.secBetweenFrom >= this.cronForm.second.secBetween.secBetweenTo) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '秒采用区间定义时，开始时间应该小于结束时间!'
                            });
                            break;
                        }
                        secondExpression = this.cronForm.second.secBetween.secBetweenFrom + '-' + this.cronForm.second.secBetween.secBetweenTo;
                        break;
                    }
                    case 'secGiven':{
                        secondExpression = this.cronForm.second.secGivenTo;
                        if (!secondExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '秒采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break;
                    }
                }
                if (error) {
                    return;
                }

                let minuteExpression = '';
                let minuteType = this.cronForm.minute.value;
                switch (minuteType) {
                    case 'minAll':{
                        minuteExpression = '*';
                        break;
                    }
                    case 'minPartition':{
                        minuteExpression = this.cronForm.minute.minPartition.minPartitionFrom + '/' + this.cronForm.minute.minPartition.minPartitionTo;
                        break;
                    }
                    case 'minBetween':{
                        if (this.cronForm.minute.minBetween.minBetweenFrom >= this.cronForm.minute.minBetween.minBetweenTo) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '分钟采用区间定义时，开始时间应该小于结束时间!'
                            });
                            break
                        }
                        minuteExpression = this.cronForm.minute.minBetween.minBetweenFrom + '-' + this.cronForm.minute.minBetween.minBetweenTo;
                        break;
                    }
                    case 'minGiven':{
                        minuteExpression = this.cronForm.minute.minGivenTo;
                        if (!minuteExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '分钟采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break
                    }
                }
                if (error) {
                    return;
                }
                // 小时
                let hourExpression = '';
                let hourType = this.cronForm.hour.value;
                switch (hourType) {
                    case 'hourAll':{
                        hourExpression = '*';
                        break;
                    }
                    case 'hourPartition':{
                        hourExpression = this.cronForm.hour.hourPartition.hourPartitionFrom + '/' + this.cronForm.hour.hourPartition.hourPartitionTo;
                        break;
                    }
                    case 'hourBetween':{
                        if (this.cronForm.hour.hourBetween.hourBetweenFrom >= this.cronForm.hour.hourBetween.hourBetweenTo) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '小时采用区间定义时，开始时间应该小于结束时间!'
                            });
                            break
                        }
                        hourExpression = this.cronForm.hour.hourBetween.hourBetweenFrom + '-' + this.cronForm.hour.hourBetween.hourBetweenTo;
                        break;
                    }
                    case 'hourGiven':{
                        hourExpression = this.cronForm.hour.hourGivenTo;
                        if (!hourExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '小时采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break
                    }
                }
                if (error) {
                    return
                }
                // 日期
                let dayExpression = '';
                let dayType = this.cronForm.day.value;
                switch (dayType) {
                    case 'dayAll':{
                        dayExpression = '*';
                        break;
                    }
                    case 'dayPartition':{
                        dayExpression = this.cronForm.day.dayPartition.dayPartitionFrom + '/' + this.cronForm.day.dayPartition.dayPartitionTo;
                        break;
                    }
                    case 'dayBetween':{
                        if (this.cronForm.day.dayBetween.dayBetweenFrom >= this.cronForm.day.dayBetween.dayBetweenTo) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '日期采用区间定义时，开始时间应该小于结束时间!'
                            });
                            break
                        }
                        dayExpression = this.cronForm.day.dayBetween.dayBetweenFrom + '-' + this.cronForm.day.dayBetween.dayBetweenTo;
                        break;
                    }
                    case 'dayGiven':{
                        dayExpression = this.cronForm.day.dayGivenTo;
                        if (!dayExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '日期采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break;
                    }
                    case 'dayLast':{
                        dayExpression = 'L';
                        break;
                    }
                }
                if (error) {
                    return;
                }
                // 月份
                let monthExpression = '';
                let monthType = this.cronForm.month.value;
                switch (monthType) {
                    case 'monthAll':{
                        monthExpression = '*';
                        break;
                    }
                    case 'monthPartition':{
                        monthExpression = this.cronForm.month.monthPartition.monthPartitionFrom + '/' + this.cronForm.month.monthPartition.monthPartitionTo;
                        break;
                    }
                    case 'monthBetween':{
                        if (this.cronForm.month.monthBetween.monthBetweenFrom >= this.cronForm.month.monthBetween.monthBetweenTo) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '月份采用区间定义时，开始时间应该小于结束时间!'
                            });
                            break;
                        }
                        monthExpression = this.cronForm.month.monthBetween.monthBetweenFrom + '-' + this.cronForm.month.monthBetween.monthBetweenTo;
                        break;
                    }
                    case 'monthGiven':{
                        monthExpression = this.cronForm.month.monthGivenTo;
                        if (!monthExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '月份采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break
                    }
                }
                if (error) {
                    return;
                }
                // 星期
                let weekExpression = '';
                let weekType = this.cronForm.week.value;
                switch (weekType) {
                    case 'weekAll':{
                        weekExpression = '*';
                        break;
                    }
                    case 'weekPartition':{
                        weekExpression = this.cronForm.week.weekPartition.weekPartitionFrom + '/' + this.cronForm.week.weekPartition.weekPartitionTo;
                        break;
                    }
                    case 'weekBetween':{
                        weekExpression = this.cronForm.week.weekBetween.weekBetweenFrom + '-' + this.cronForm.week.weekBetween.weekBetweenTo;
                        break;
                    }
                    case 'weekGiven':{
                        weekExpression = this.cronForm.week.weekGivenTo;
                        if (!weekExpression) {
                            error = true;
                            this.$Notice.error({
                                title: '错误',
                                desc: '星期采用特定值时，必须要填充至少一个特定值!'
                            });
                        }
                        break;
                    }
                    case 'weekLast':{
                        weekExpression = this.cronForm.week.weekLastTo + 'L';
                        break;
                    }
                }
                if (error) {
                    return;
                }
                if (dayExpression !== '*' && weekExpression !== '*') {
                    this.$Notice.error({
                        title: '错误',
                        desc: '日期与星期的值至少有一个需要设置成所有，当前日期与星期的值皆不为所有!'
                    });
                    return;
                }
                if (dayExpression === '*') {
                    dayExpression = '?';
                } else {
                    weekExpression = '?';
                }

                let expression = secondExpression + ' ' + minuteExpression + ' ' + hourExpression + ' ' + dayExpression + ' ' + monthExpression + ' ' + weekExpression;
                this.cronModalProps.cronText = expression;
            },
            selectBack (cronExpr) {
                if (!cronExpr) {
                    return;
                }
                let cron = cronExpr.split(' ');
                if (cron.length < 5) {
                    this.$Notice.error({
                        title: '错误',
                        desc: '无法解析当前Cron表达式[' + cronExpr + ']'
                    });
                }

                let second = cron[0];
                if (second === '*') {
                    this.cronForm.second.value = 'secAll'
                } else {
                    let partition = second.split('/');
                    let between = second.split('-');
                    if (between.length > 1) {
                        this.cronForm.second.value = 'secBetween';
                        this.cronForm.second.secBetween.secBetweenFrom = between[0];
                        this.cronForm.second.secBetween.secBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.second.value = 'secPartition';
                        this.cronForm.second.secPartition.secPartitionFrom = partition[0];
                        this.cronForm.second.secPartition.secPartitionTo = partition[1];
                    } else {
                        let array = second.split(',');
                        this.cronForm.second.value = 'secGiven';
                        this.cronForm.second.secGivenTo = array;
                    }
                }
                // -------------------------
                let minute = cron[1];
                if (minute === '*') {
                    this.cronForm.minute.value = 'minAll';
                } else {
                    let partition = minute.split('/');
                    let between = minute.split('-');
                    if (between.length > 1) {
                        this.cronForm.minute.value = 'minBetween';
                        this.cronForm.minute.minBetween.minBetweenFrom = between[0];
                        this.cronForm.minute.minBetween.minBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.minute.value = 'minPartition';
                        this.cronForm.minute.minPartition.minPartitionFrom = partition[0];
                        this.cronForm.minute.minPartition.minPartitionTo = partition[1];
                    } else {
                        let array = minute.split(',');
                        this.cronForm.minute.value = 'minGiven';
                        this.cronForm.minute.minGivenTo = array;
                    }
                }
                // -------------------------
                let hour = cron[2];
                if (hour === '*') {
                    this.cronForm.hour.value = 'hourAll'
                } else {
                    let partition = hour.split('/');
                    let between = hour.split('-');
                    if (between.length > 1) {
                        this.cronForm.hour.value = 'hourBetween';
                        this.cronForm.hour.hourBetween.hourBetweenFrom = between[0];
                        this.cronForm.hour.hourBetween.hourBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.hour.value = 'hourPartition';
                        this.cronForm.hour.hourPartition.hourPartitionFrom = partition[0];
                        this.cronForm.hour.hourPartition.hourPartitionTo = partition[1];
                    } else {
                        let array = hour.split(',');
                        this.cronForm.hour.value = 'hourGiven';
                        this.cronForm.hour.hourGivenTo = array;
                    }
                }
                // -------------------------
                let day = cron[3];
                if (day === '*' || day === '?') {
                    this.cronForm.day.value = 'dayAll';
                } else if (day === 'L') {
                    this.cronForm.day.value = 'dayLast';
                } else {
                    let partition = day.split('/');
                    let between = day.split('-');
                    if (between.length > 1) {
                        this.cronForm.day.value = 'dayBetween';
                        this.cronForm.day.dayBetween.dayBetweenFrom = between[0];
                        this.cronForm.day.dayBetween.dayBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.day.value = 'dayPartition';
                        this.cronForm.day.dayPartition.dayPartitionFrom = partition[0];
                        this.cronForm.day.dayPartition.dayPartitionTo = partition[1];
                    } else {
                        let array = day.split(',');
                        this.cronForm.day.value = 'dayGiven';
                        this.cronForm.day.dayGivenTo = array;
                    }
                }
                // -------------------------
                let month = cron[4];
                if (month === '*') {
                    this.cronForm.month.value = 'monthAll';
                } else {
                    let partition = month.split('/');
                    let between = month.split('-');
                    if (between.length > 1) {
                        this.cronForm.month.value = 'monthBetween';
                        this.cronForm.month.monthBetween.monthBetweenFrom = between[0];
                        this.cronForm.month.monthBetween.monthBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.month.value = 'monthPartition';
                        this.cronForm.month.monthPartition.monthPartitionFrom = partition[0];
                        this.cronForm.month.monthPartition.monthPartitionTo = partition[1];
                    } else {
                        let array = month.split(',');
                        this.cronForm.month.value = 'monthGiven';
                        this.cronForm.month.monthGivenTo = array;
                    }
                }
                // -------------------------
                let week = cron[5];
                if (week === '*' || week === '?') {
                    this.cronForm.week.value = 'weekAll';
                } else if (week.indexOf('L') > 0) {
                    this.cronForm.week.value = 'weekLast';
                    let weekL = week.substring(0, week.length - 1);
                    this.cronForm.week.weekLastTo = weekL;
                } else {
                    let partition = week.split('/');
                    let between = week.split('-');
                    if (between.length > 1) {
                        this.cronForm.week.value = 'weekBetween';
                        this.cronForm.week.weekBetween.weekBetweenFrom = between[0];
                        this.cronForm.week.weekBetween.weekBetweenTo = between[1];
                    } else if (partition.length > 1) {
                        this.cronForm.week.value = 'weekPartition';
                        this.cronForm.week.weekPartition.weekPartitionFrom = partition[0];
                        this.cronForm.week.weekPartition.weekPartitionTo = partition[1];
                    } else {
                        let array = week.split(',');
                        this.cronForm.week.value = 'weekGiven';
                        this.cronForm.week.weekGivenTo = array;
                    }
                }
            },
            async analyCron () {
                this.analyLoding = true;
                let map = { cronStr: this.cronModalProps.cronText };
                const res = await cronDate(map);
                this.analyLoding = false;
                if (res.data.code === 200) {
                    this.execTimeModal.show = true;
                    this.execTimeModal.times = res.data.data;
                }
            },
            handleChangeTab (name) {
                if (name === '0') {
                    this.createCronBtn = true;
                } else {
                    this.createCronBtn = false;
                }
            },
            handleChangeCron () {
                this.selectBack(this.cronText);
            },
            handleCancel () {
                this.cronModalProps.show = false;
                this.$emit('update:cronModalProps', this.cronModalProps);
            },
            handleOk () {
                if (this.cronModalProps.cronText) {
                    this.$emit('on-change', this.cronModalProps.cronText);
                    this.cronModalProps.show = false;
                    this.$emit('update:cronModalProps', this.cronModalProps);
                } else {
                    this.$Message.error('请配置任务表达式');
                }
            }
        }
    }
</script>
