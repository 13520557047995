<template>
    <Modal title="运行参数配置" v-model="jobParamsModalProps.show" width="650">
        <Alert type="warning" show-icon>任务重启后生效</Alert>
        <Button type="primary" icon="md-add" @click="handleAdd">添加</Button>
        <Table border :columns="columns" :data="cacheData" class="ivu-mt-8"></Table>
        <div slot="footer">
            <Button type="text" @click="handleCancel">取消</Button>
            <Button type="primary" :loading="saveBtnLoading" @click="handleOk">确定</Button>
        </div>
    </Modal>
</template>
<script>
    import { cloneDeep } from 'lodash';
    import { addJobParam } from '/src/api/system/job';
    export default {
        name: 'jobParams',
        props: {
            jobParamsModalProps: {
                show: false,
                jobId: '',
                tableData: []
            }
        },
        data () {
            return {
                editIndex: -1,
                editName: '',
                editValue: '',
                saveBtnLoading: false,
                cacheData: [],
                columns: [
                    {
                        title: '键',
                        align: 'center',
                        key: 'name',
                        render: (h, { row, index }) => {
                            let edit;
                            if (this.editIndex === index) {
                                edit = [h('Input', {
                                    props: {
                                        value: row.name
                                    },
                                    on: {
                                        input: (val) => {
                                            this.editName = val;
                                        }
                                    }
                                })];
                            } else {
                                edit = row.name;
                            }
                            return h('div', [
                                edit
                            ]);
                        }
                    },
                    {
                        title: '值',
                        align: 'center',
                        key: 'value',
                        render: (h, { row, index }) => {
                            let edit;
                            if (this.editIndex === index) {
                                edit = [h('Input', {
                                    props: {
                                        value: row.value
                                    },
                                    on: {
                                        input: (val) => {
                                            this.editValue = val;
                                        }
                                    }
                                })];
                            } else {
                                edit = row.value;
                            }
                            return h('div', [
                                edit
                            ]);
                        }
                    },
                    {
                        title: '操作',
                        width: 150,
                        align: 'center',
                        render: (h, { row, index }) => {
                            if (this.editIndex === index) {
                                return [
                                    h('a', {
                                        on: {
                                            click: () => {
                                                if (!this.editName || !this.editValue) {
                                                    this.$Message.error('键值信息不完整');
                                                } else {
                                                    this.cacheData[index].name = this.editName;
                                                    this.cacheData[index].value = this.editValue;
                                                    if (!this.jobParamsModalProps.tableData[index]) {
                                                        this.jobParamsModalProps.tableData.push({
                                                            name: this.editName,
                                                            value: this.editValue
                                                        })
                                                    }
                                                    this.editIndex = -1;
                                                }
                                            }
                                        }
                                    }, '保存'),
                                    h('Divider', {
                                        props: {
                                            type: 'vertical'
                                        }
                                    }),
                                    h('a', {
                                        on: {
                                            click: () => {
                                                if (!this.jobParamsModalProps.tableData[index]) {
                                                    this.cacheData.splice(index, 1);
                                                }
                                                this.editIndex = -1;
                                            }
                                        }
                                    }, '取消')
                                ]
                            } else {
                                return [
                                    h('a', {
                                        on: {
                                            click: () => {
                                                this.editName = row.name;
                                                this.editValue = row.value;
                                                this.editIndex = index;
                                            }
                                        }
                                    }, '修改'),
                                    h('Divider', {
                                        props: {
                                            type: 'vertical'
                                        }
                                    }),
                                    h('a', {
                                        on: {
                                            click: () => {
                                                this.cacheData.splice(index, 1);
                                            }
                                        }
                                    }, '删除')
                                ];
                            }
                        }
                    }
                ]
            }
        },
        methods: {
            handleAdd () {
                if (this.editIndex > -1) {
                    this.$Message.error('请先保存');
                } else {
                    this.cacheData.push({ name: '', value: '' });
                    this.editIndex = this.cacheData.length - 1;
                }
            },
            handleCancel () {
                this.jobParamsModalProps.show = false;
                this.$emit('update:jobParamsModalProps', this.jobParamsModalProps);
            },
            async handleOk () {
                if (this.editIndex > -1) {
                    this.$Message.error('请先保存');
                } else {
                    this.saveBtnLoading = true;
                    this.cacheData.forEach(item => {
                        item.jobId = this.jobParamsModalProps.jobId
                    });
                    const params = {
                        jobId: this.jobParamsModalProps.jobId,
                        jobParams: this.cacheData
                    };
                    const res = await addJobParam(params);
                    this.saveBtnLoading = false;
                    if (res.data.state === 'ok') {
                        this.jobParamsModalProps.show = false;
                        this.$emit('on-save', this.cacheData);
                        this.$emit('update:jobParamsModalProps', this.jobParamsModalProps);
                        this.$Message.success('保存成功');
                    }
                }
            }
        },
        watch: {
            jobParamsModalProps: {
                deep: true,
                handler (newVal) {
                    if (newVal.show) {
                        this.cacheData = cloneDeep(newVal.tableData);
                    }
                }
            }
        }
    }
</script>
