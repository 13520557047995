import { post, get} from '/src/lib/request.js'

/**
 * 获取所有定时任务
 * @param params
 */
export const getAllJobs = (pages, params) => {
    return post(`/job/getAllJobs?${pages}`, params)
};

/**
 * 添加定时任务
 * @param params
 */
export const addJob = (params) => {
    return post('/job/addJob', params)
};

/**
 * 修改定时任务
 * @param params
 */
export const updateJob = (params) => {
    return post('/job/updateJob', params)
};

/**
 * 立即运行一次任务
 * @param params
 */
export const runOnceJob = (params) => {
    return post('/job/runOnceJob', params)
};

/**
 * 启动定时任务
 * @param params
 */
export const startJob = (params) => {
    return post('/job/startJob', params)
};

/**
 * 重启任务
 * @param params
 */
export const resumeJob = (params) => {
    return post('/job/resumeJob', params)
};

/**
 * 停止任务
 * @param params
 */
export const stopJob = (params) => {
    return post('/job/stopJob', params)
};

/**
 * 解析定时任务执行时间
 * @param params
 */
export const cronDate = (params) => {
    return post('/job/cronDate', params)
};

/**
 * 获取预设的cron表达式
 * @param params
 */
export const getPreCronMap = () => {
    return get( '/job/getPreCronMap', {})
};

/**
 * 添加job运行参数
 * @param params
 */
export const addJobParam = (params) => {
    return post('/job/addJobParam', params)
};

/**
 * 历史记录分页
 * @param params
 */
export const getJobHistoryPage = (pages, params) => {
    return post(`/job/getJobHistoryPages?${pages}`, params)
};

