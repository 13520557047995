<template>
    <div>
        <div style="padding: 10px 20px">
            <Row slot="content" type="flex" justify="center" align="middle" class="ivu-text-center">
                <Col span="4">
                    <p>状态</p>
                    <p v-if="table.jobOnline"  style="position:relative">
                        <span v-if="table.jobOnline" style="color:#19be6b"><Icon type="ios-flash" color="#19be6b"/>在线</span>
                        <span v-else style="color:#ed4014"><Icon type="ios-flash" color="#ed4014"/>离线</span>
                    </p>
                    <p v-else>-</p>
                </Col>
                <Col span="5">
                    <p>总任务</p>
                    <p style="position:relative">
                        <span v-if="table.jobOnline">{{table.allNum}} 个任务</span>
                        <span v-else>-</span>
                    </p>
                </Col>
                <Col span="5">
                    <p>已启动</p>
                    <p  style="color:#2b85e4">
                        <span v-if="table.jobOnline">{{table.startNum}} 个任务</span>
                        <span v-else>-</span>
                    </p>
                </Col>
                <Col span="5">
                    <p>运行中</p>
                    <p  style="color:#19be6b">
                        <span v-if="table.jobOnline">{{table.runNum}} 个任务</span>
                        <span v-else>-</span>
                    </p>
                </Col>
                <Col span="5">
                    <p>已停止</p>
                    <p  style="color:#ed4014">
                        <span v-if="table.jobOnline">{{table.stopNum}} 个任务</span>
                        <span v-else>-</span>
                    </p>
                </Col>
            </Row>
        </div>
        <Card title="任务列表" :bordered="false" class="ivu-mt" dis-hover>
            <div slot="extra">
                <Row>
                    <Col>
                        <Button type="primary" size="small" icon="md-add" @click="handleAddJob">添加任务</Button>
                        <Divider type="vertical" />
                        <RadioGroup v-model="job.state" size="small" type="button" @on-change="getData">
                            <Radio label="">全部</Radio>
                            <Radio label="RUN">已启动</Radio>
                            <Radio label="STOP">已停止</Radio>
                        </RadioGroup>
                        <Divider type="vertical" />
                    </Col>
                    <Col>
                        <Input v-model="job.name" size="small" @on-search="getData()" search placeholder="请输入任务名称..." :width="200"/>
                    </Col>
                </Row>
            </div>
            <Table ref="table" :data="table.data" :columns="table.columns" :loading="table.loading" border>
                <template slot-scope="{ row }" slot="name">
                    <div style="width:310px;float:left;margin-right:10px">
                        <Tooltip v-if="row.desc" :content="row.desc" placement="top-start">
                            <p style="margin: 6px 0 4px 0;">{{row.name}}</p>
                        </Tooltip>
                        <p v-else style="margin: 6px 0 4px 0;">{{row.name}}</p>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="state">
                    <Badge v-if="row.state === 'READY'" color="blue" :text="row.state" />
                    <Badge v-if="row.state === 'RUNNING'" color="gold" :text="row.state" />
                    <Badge v-if="row.state === 'RUN'" color="green" :text="row.state" />
                    <Badge v-if="row.state === 'STOPPING'" color="gold" :text="row.state" />
                    <Badge v-if="row.state === 'STOP'" color="red" :text="row.state" />
                </template>
                <template slot-scope="{ row }" slot="prevFireTime">
                    <Time v-if="row.prevFireTime" :time="row.prevFireTime" type="datetime"/>
                </template>
                <template slot-scope="{ row }" slot="nextFireTime">
                    <Time v-if="row.nextFireTime" :time="row.nextFireTime" type="datetime"/>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Tooltip content="编辑" placement="top" transfer>
                        <a href="javascript:void(0)" @click="handleJob(row,'edit','编辑',index)"
                           :disabled="(row.state !== 'READY' && row.state !== 'STOP') || !table.jobOnline">
                            <Icon type="ios-build" :size="18" :color="(row.state === 'READY' || row.state === 'STOP') && table.jobOnline?'#2D8cF0':'#c5c8ce'"/>
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip content="运行参数" placement="top" transfer="">
                        <a href="javascript:void(0)" @click="handleJob(row, 'params', '参数', index)">
                            <Icon type="ios-list-box" :size="18" :color="'purple'"/>
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip content="启动" placement="top" transfer>
                        <a href="javascript:void(0)" @click="handleJob(row,'start','启动',index)" :disabled="(row.state !== 'READY' && row.state !== 'STOP') || !table.jobOnline">
                            <Icon type="md-play" :size="18" :color="(row.state === 'READY' || row.state === 'STOP') && table.jobOnline?'#19be6b':'#c5c8ce'"/>
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip content="停止" placement="top" transfer>
                        <a href="javascript:void(0)" @click="handleJob(row,'stop','停止',index)" :disabled="row.state !== 'RUN' || !table.jobOnline">
                            <Icon type="ios-square" :size="18" :color="row.state === 'RUN' && table.jobOnline?'#f50':'#c5c8ce'"/>
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip content="运行一次" placement="top" transfer>
                        <a href="javascript:void(0)" @click="handleJob(row,'runOnce','运行一次',index)" :disabled="!table.jobOnline">
                            <Icon type="md-arrow-dropright-circle" :size="18" :color="table.jobOnline?'#2db7f5':'#c5c8ce'"/>
                        </a>
                    </Tooltip>
                    <!--<Divider type="vertical" />-->
                    <!--<Tooltip content="执行记录" placement="top" transfer>-->
                        <!--<a href="javascript:void(0)" style="cursor: pointer;"><Icon type="md-list" :size="18" :color="'#515a6e'"/></a>-->
                    <!--</Tooltip>-->
                </template>
            </Table>
            <!--<m-page :total="page.total" :current.sync="page.current" :pageSize.sync="page.pageSize"  @changePage="getData" @handleChangePageSize="getData"></m-page>-->
            <Page class="margin_10" :total="page.total" :current="page.current" @on-change="getData" show-elevator show-sizer/>
        </Card>
        <Modal :title="jobModal.jobModalTitle" v-model="jobModal.show" :closable="false" :mask-closable="false">
            <Form ref="job_form" :model="jobModal.jobForm" :rules="jobFormRules" :label-width="120">
                <FormItem label="任务名称" prop="name" label-for="jobName">
                    <Input v-model="jobModal.jobForm.name" element-id="jobName" placeholder="请输入任务名称"
                           maxlength="30" show-word-limit></Input>
                </FormItem>
                <FormItem label="使用的BeanID" prop="beanId" label-for="jobBeanId">
                    <Select v-model="jobModal.jobForm.beanId" element-id="jobBeanId" placeholder="请选择BeanID">
                        <OptionGroup v-for="(value, key) in jobBeanIds" :key="key" :label="key">
                            <Option v-for="(item, index) in value" :value="item" :key="index">{{item}}</Option>
                        </OptionGroup>
                    </Select>
                </FormItem>
                <FormItem label="Cron表达式" prop="cronExpression" label-for="cronExpression">
                    <Input v-model="jobModal.jobForm.cronExpression" element-id="cronExpression" placeholder="请配置Cron表达式" readonly>
                        <Button slot="append" icon="md-create" @click="handleConfigCron"></Button>
                    </Input>
                </FormItem>
                <FormItem label="描述" prop="desc" label-for="jobDesc">
                    <Input v-model="jobModal.jobForm.desc" type="textarea" :autosize="{minRows: 5,maxRows: 8}"
                           element-id="jobDesc" placeholder="请输入描述" maxlength="200" show-word-limit></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" @click="jobModal.show = false">取消</Button>
                <Button type="primary" :loading="jobModal.saveBtnLoading" @click="handleSaveJob">保存</Button>
            </div>
        </Modal>
        <cron-setting ref="cronSetting" :cronModalProps.sync="cronModalProps" @on-change="handleChangeCronExpression" />
        <job-params ref="jobParams" :jobParamsModalProps.sync="jobParamsModal" @on-save="handleSaveJobParams"></job-params>
    </div>
</template>
<script>
    import { cloneDeep } from 'lodash';
    import cronSetting from './cron-setting';
    import jobParams from './job-params';
    import { getAllJobs, addJob, updateJob, runOnceJob, startJob, stopJob, getPreCronMap } from '/src/api/system/job';

    export default {
        name: 'JobConfig',
        components: {cronSetting, jobParams },
        data () {
            return {
                page: {
                    total: 0,
                    current: 1,
                    pageSize: 10
                },
                // 搜索数据
                job: {
                    state: '',
                    name: ''
                },
                table: {
                    loading: false,
                    data: [],
                    columns: [
                        {
                            type: 'index',
                            width: 60,
                            align: 'center',
                            indexMethod: (row) => {
                                return (row._index + 1) + (this.page.pageSize * this.page.current) - this.page.pageSize;
                            }
                        },
                        {
                            title: '任务名称',
                            key: 'name',
                            slot: 'name',
                            minWidth: 150
                        },
                        {
                            title: '使用的BeanID',
                            key: 'beanId',
                            align: 'center',
                            width: 200
                        },
                        {
                            title: '当前状态',
                            key: 'state',
                            slot: 'state',
                            align: 'center',
                            width: 120
                        },
                        {
                            title: '上次执行时间',
                            key: 'prevFireTime',
                            align: 'center',
                            slot: 'prevFireTime',
                            width: 170
                        },
                        {
                            title: '下次执行时间',
                            key: 'nextFireTime',
                            align: 'center',
                            slot: 'nextFireTime',
                            width: 170
                        },
                        {
                            title: 'Cron表达式',
                            key: 'cronExpression',
                            align: 'center',
                            width: 120
                        },
                        {
                            title: '操作',
                            slot: 'action',
                            align: 'center',
                            width: 200,
                            fixed: 'right'
                        }
                    ],
                    allNum: 0,
                    startJob: 0,
                    runNum: 0,
                    stopNum: 0,
                    jobOnline: false
                },
                jobModal: {
                    show: false,
                    jobModalTitle: '添加定时任务',
                    saveBtnLoading: false,
                    opType: '',
                    editIndex: 0,
                    jobForm: {
                        name: '',
                        beanId: '',
                        cronExpression: '',
                        desc: ''
                    }
                },
                cronModalProps: {
                    show: false,
                    preCronMap: [],
                    cronText: ''
                },
                jobParamsModal: {
                    show: false,
                    editIndex: 0,
                    jobId: '',
                    tableData: []
                },
                jobBeanIds: []
            }
        },
        computed: {
            jobFormRules () {
                return {
                    name: [
                        { required: true, whitespace: true, message: '任务名称不能为空', trigger: 'blur' }
                    ],
                    beanId: [
                        { required: true, whitespace: true, message: 'BeanId不能为空', trigger: 'blur' }
                    ],
                    cronExpression: [
                        { required: true, whitespace: true, message: 'Cron表达式不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            // 任务操作
            async handleJob (row, state, des, index) {
                let res;
                if (state === 'edit') {
                    this.jobModal.opType = 'edit';
                    this.jobModal.show = true;
                    this.jobModal.jobForm = cloneDeep(row);
                    this.jobModal.editIndex = index;
                } else if (state === 'start') {
                    this.$Message.loading({
                        content: '正在启动......',
                        duration: 0
                    });
                    res = await startJob(row.pkId);
                    this.$Message.destroy();
                    if (res.data.code === 200) {
                        row.state = 'RUNNING';
                        this.$Message.success(`任务: ${row.name}${des}成功`);
                    }
                } else if (state === 'stop') {
                    this.$Message.loading({
                        content: '正在停止......',
                        duration: 0
                    });
                    res = await stopJob(row.pkId);
                    this.$Message.destroy();
                    if (res.data.code === 200) {
                        row.state = 'STOPPING';
                        this.$Message.success(`任务: ${row.name}${des}成功`);
                    }
                } else if (state === 'runOnce') {
                    // 运行一次
                    res = await runOnceJob(row.pkId);
                    if (res.data.code === 200) {
                        this.$Message.success(`任务: ${row.name}${des}运行成功`);
                    } else {
                        this.$Message.error(res.data.message);
                    }
                } else if (state === 'params') {
                    this.jobParamsModal.tableData = cloneDeep(row.parameters);
                    this.jobParamsModal.jobId = row.pkId;
                    this.jobParamsModal.editIndex = index;
                    this.jobParamsModal.show = true;
                }
            },
            async getData () {
                this.table.loading = true;
                const pages = `size=${this.page.pageSize}&page=${this.page.current - 1}`;
                let res = await getAllJobs(pages, this.job);
                console.log("getAllJobs>>>>>>>>>>>>>>", res)
                console.log(JSON.stringify(res))
                if (res.data.code === 200) {
                    let data = res.data.data;
                    this.table.data = data.jobs.content;
                    this.table.allNum = data.allNum;
                    this.table.startNum = data.startNum;
                    this.table.runNum = data.runNum;
                    this.table.stopNum = data.stopNum;
                    this.table.jobOnline = data.jobOnline;
                    this.page.total = data.jobs.totalElements;
                }
                this.table.loading = false;
            },
            // 获取预设的cron表达式
            async getPreCronMap () {
                let res = await getPreCronMap();
                console.log("getPreCronMap>>>>>>>>>>>>>>", res)
                console.log(JSON.stringify(res))
                if (res.data.code === 200) {
                    this.cronModalProps.preCronMap = res.data.data.preCron;
                    this.jobBeanIds = res.data.data.beanIds;
                }
            },
            handleAddJob () {
                this.$refs['job_form'].resetFields();
                delete this.jobModal.jobForm.pkId;
                this.jobModal.opType = 'add';
                this.jobModal.show = true;
            },
            handleConfigCron () {
                this.cronModalProps.cronText = this.jobModal.jobForm.cronExpression;
                this.$refs['cronSetting'].selectBack(this.jobModal.jobForm.cronExpression);
                this.cronModalProps.show = true;
            },
            handleChangeCronExpression (cronExpression) {
                this.jobModal.jobForm.cronExpression = cronExpression;
                this.$refs['job_form'].validateField('cronExpression');
            },
            async handleSaveJob () {
                this.$refs['job_form'].validate(async (valid) => {
                    if (valid) {
                        this.jobModal.saveBtnLoading = true;
                        if (this.jobModal.opType === 'add') {
                            const res = await addJob(this.jobModal.jobForm);
                            if (res.data.code === 200) {
                                this.jobModal.jobForm.pkId = res.data.data;
                                let newJob = Object.assign({}, this.jobModal.jobForm, {
                                    pkId: res.data.data,
                                    state: {
                                        label: '待命',
                                        color: 'blue'
                                    }
                                });
                                this.table.data.unshift(newJob);
                                this.$Message.success('保存成功');
                            }
                        } else if (this.jobModal.opType === 'edit') {
                            delete this.jobModal.jobForm.state;
                            const res = await updateJob(this.jobModal.jobForm);
                            if (res.data.code === 200) {
                                this.$Message.success('修改成功');
                            }
                        }
                        this.jobModal.show = false;
                        this.jobModal.saveBtnLoading = false;
                    }
                });
            },
            handleSaveJobParams (jobParams) {
                this.table.data[this.jobParamsModal.editIndex].parameters = jobParams;
            }
        },
        created () {
            this.getData();
            this.getPreCronMap();
        }
    }
</script>
<style lang="less">
</style>
